import { render, staticRenderFns } from "./ReportTrip.vue?vue&type=template&id=58b4cdd0&scoped=true&"
import script from "./ReportTrip.vue?vue&type=script&lang=js&"
export * from "./ReportTrip.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58b4cdd0",
  null
  
)

export default component.exports
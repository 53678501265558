<template>
  <b-container class="w-100" ref="footer" @mousemove="showButton" @mouseleave="hideButton">
    <transition :name="!showFooter ? 'fade-down' : 'fade-up'">
      <b-row class="b-c-green-opacity pt-3" v-show="showFooter">
        <b-container>
          <b-row align-h="center" style="height: 0">
            <transition :name="showFooter ? 'fade-down' : 'fade-up'">
              <i class="my-button-footer-down hover-enabled fas fa-chevron-down c-yellow p-4 fa-3x" v-show="showFooter && showButtons" v-on:click="checkFooter"/>
            </transition>
          </b-row>

          <b-row allign-h="center" v-on:click="showButtons = true">
            <b-col cols="12" sm="12" md="6" :lg="logged ? '4' : '6'" :xl="logged ? '4' : '6'" class="mb-4">
              <h4 class="d-inline-flex c-white"> bim </h4>
              <h4 class="d-inline-flex c-yellow"> bum </h4>
              <h4 class="d-inline-flex c-white"> bus </h4>
            </b-col>

            <b-col cols="12" sm="12" md="6" :lg="logged ? '4' : '6'" :xl="logged ? '4' : '6'" class="mb-4">
              <b-container>
                <b-row>
                  <b-col cols="12">
                    <h5 class="c-yellow">{{$tc('message.contact', 2)}}</h5>
                  </b-col>

                  <b-col cols="12">
                    <i class="fas fa-phone"/> +39 050 0986268
                  </b-col>

                  <b-col cols="12">
                    <i class="far fa-envelope"/> info@awtech.it
                  </b-col>

                  <b-col cols="12">
                    <i class="fas fa-external-link-alt"/> <a href="https://www.awtech.it" class="c-white h-c-yellow">www.awtech.it</a>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>

            <b-col cols="12" sm="12" md="12" lg="4" xl="4" v-if="logged" class="mb-4">
              <b-container>
                <h5 class="c-yellow" id="nw-navigate">{{$t('message.navigate')}}</h5>

                <b-row v-for="button in buttons" :key="button.href">
                  <b-col>
                    <router-link :to="button.href" class="mr-2 c-white h-c-yellow">
                      {{button.title}}<i class="fas fa-angle-right ml-1"/>
                    </router-link>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
        </b-container>
      </b-row>
    </transition>

    <b-row class="b-c-black">
      <b-container class="mw-100 m-2">
        <b-row align-h="center" style="height: 0">
          <transition :name="showFooter ? 'fade-down' : 'fade-up'">
            <i class="my-button-footer-up hover-enabled c-yellow p-4 fas fa-chevron-up fa-3x"
               v-show="!showFooter && showButtons" v-on:click="checkFooter"/>
          </transition>
        </b-row>

        <b-row align-v="center" v-on:click="showButtons = true">
          <b-col cols="auto" sm="auto" md="auto" lg="auto" xl="auto" class="mr-auto p-0">
            <changeLanguage/>
          </b-col>

          <b-col cols="auto" sm="auto" md="auto" lg="auto" xl="auto" class="mx-auto p-0">
            <h5 class="mb-0">{{!isMobile ? 'Powered by' : ''}} © Geoin & AWTech</h5>
          </b-col>

          <b-col cols="auto" sm="auto" md="auto" lg="auto" xl="auto" class="ml-auto p-0">
            <i class="fas fa-arrow-alt-circle-up fa-2x hover-enabled d-flex align-items-center"
               v-if="hasScrollbar" v-on:click="gotToTop"/>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </b-container>
</template>

<script>
  import Vue from 'vue'
  import routeViews from '../router-views'
  import changeLanguage from '../components/vue-plugins/ChangeLanguage'

  export default {
    name: 'Footer',
    components: {
      changeLanguage
    },
    mounted () {
      this.handleScroll()
      this.checkSize()
      this.$root.$on('scroll', () => this.handleScroll())
      this.$root.$on('resize', () => this.checkSize())
      this.getButtons()
      this.$root.$on('change-lang', () => {
        this.getButtons()
      })
    },
    watch: {
      '$route' (to) {
        const session = Vue.getLocalStorage('session')
        const showFooter = Vue.getLocalStorage('showFooter')
        this.logged = !!(session && session.token)
        this.getButtons()
        if (to.path === '/home') {
          this.showFooter = false
        } else {
          this.showFooter = showFooter
        }
      }
    },
    data () {
      const hasScrollbar = window.scrollY > 0
      const session = Vue.getLocalStorage('session')
      const showFooter = Vue.getLocalStorage('showFooter')
      return {
        hasScrollbar,
        buttons: [],
        isMD: false,
        isMobile: true,
        showFooter: showFooter,
        showButtons: false,
        logged: !!(session && session.token)
      }
    },
    methods: {
      handleScroll () {
        this.hasScrollbar = window.scrollY > 0
      },
      gotToTop () {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        this.$nextTick(() => {
          this.showButtons = false
        })
      },
      showButton () {
        this.showButtons = true
      },
      hideButton () {
        this.showButtons = false
      },
      checkFooter () {
        this.showFooter = !this.showFooter
        if (this.isMobile) {
          this.showButtons = false
        }
        Vue.setLocalStorage('showFooter', this.showFooter)
        this.$nextTick(() => {
          if (this.showFooter) {
            window.scrollTo(0, document.body.scrollHeight)
          }
        })
      },
      getButtons () {
        const session = Vue.getLocalStorage('session')
        const buttons = routeViews.footerButtons(this, session && session.utente && session.utente.ruolo && session.utente.ruolo.id)
        const editedButtons = []
        buttons.forEach((button) => {
          let name = ''
          if (button.title && Array.isArray(button.title)) {
            button.title.forEach((nameChunk, index) => {
              if (!isNaN(nameChunk.num) && index === 0 && this && this.$tc) {
                name += `${this.$tc(nameChunk.option, nameChunk.num)} `
              } else if (!isNaN(nameChunk.num) && index !== 0 && this && this.$tc) {
                name += `${this.$tc(nameChunk.option, nameChunk.num).toLowerCase()} `
              } else if ((nameChunk.num === null || nameChunk.num === undefined) && index !== 0 && this && this.$tc) {
                name += `${this.$t(nameChunk.option).toLowerCase()} `
              } else if (this && this.$t) {
                name += `${this.$t(nameChunk.option)} `
              }
            })
          } else {
            name = button.title
          }
          editedButtons.push({ href: button.href, title: name })
        })
        this.buttons = editedButtons
      },
      checkSize () {
        const size = Vue.myGetWindowSize()
        this.isMD = size === 'md'
        this.isMobile = size !== 'lg' && size !== 'xl'
      }
    }
  }
</script>

<style scoped>
  .w-100 {
    max-width: 100% !important;
  }

  .my-button-footer-down {
    position: relative;
    bottom: 100px;
  }

  .my-button-footer-up {
    position: relative;
    bottom: 95px;
  }
</style>

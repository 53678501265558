<template>
    <b-container fluid>
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <header class="modal-header">
                    <h5 class="my-modal-title">{{$tc('message.child', 1)}}</h5>
                </header>

                <div class="modal-body">
                    <ValidationObserver ref="form" v-slot="{ validate, invalid, pending }">
                        <b-row>
                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="6" xl="6">
                                <!-- Name -->
                                <ValidationProvider :rules="{ required: true, max: 50 }" :name="`${$tc('message.name', 1)} (*)`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.name', 1)} (*)`">
                                        <b-form-input id="name"
                                                      type="text"
                                                      v-model="input.nome"
                                                      :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Surname -->
                                <ValidationProvider :rules="{ required: true, max: 50 }" :name="`${$tc('message.surname', 1)} (*)`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.surname', 1)} (*)`">
                                        <b-form-input id="surname"
                                                      type="text"
                                                      v-model="input.cognome"
                                                      :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Fiscal code -->
                                <ValidationProvider :rules="{ taxCode: true, required: true }" :name="`${$tc('message.taxCode', 1)}`"
                                                    ref="validationTaxCode"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.taxCode', 1)} (*)`">
                                        <b-form-input id="child-fiscal-code"
                                                      v-model="input.codice_fiscale"
                                                      :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="6" xl="6">
                                <!-- Birth date -->
                                <ValidationProvider :rules="{ required: true }" :name="`${$tc('message.birthDate', 1)} (*)`"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.birthDate', 1)} (*)`">
                                        <b-input-group>
                                            <flat-pickr id="birth-date"
                                                        ref="birthDate"
                                                        :class="getFlatPickrClass('birthDate', valid, errors, itemChanged, true)"
                                                        v-model="input.data_nascita"
                                                        :config="birthDateConfig">
                                            </flat-pickr>

                                            <div class="input-group-append">
                                                <b-button class="fas fa-undo"
                                                          @click="input.data_nascita = getInitialBirthDate()"
                                                          :disabled="!input.data_nascita">
                                                </b-button>
                                            </div>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-input-group>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- City -->
                                <ValidationProvider :rules="{ max: 30, required: true }" :name="`${$tc('message.commonResidence', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.commonResidence', 1)} (*)`">
                                        <b-form-input id="city"
                                                      v-model="input.comune_residenza"
                                                      :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Country -->
                                <ValidationProvider :rules="{ max: 30, required: true }" :name="`${$tc('message.provinceResidence', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.provinceResidence', 1)} (*)`">
                                        <b-form-input id="province"
                                                      v-model="input.provincia_residenza"
                                                      :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col cols="12" sm="8" md="9" lg="12" xl="12">
                                <!-- Address -->
                                <ValidationProvider :rules="{ max: 50, required: true }" :name="`${$tc('message.address', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.address', 1)} (*)`">
                                        <b-form-input id="address"
                                                      type="text"
                                                      v-model="input.indirizzo_residenza"
                                                      :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>

                        <b-form-row class="border m-2"/>

                        <b-row>
                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="6" xl="6">
                                <!-- Parent 1 -->
                                <ValidationProvider :rules="{ required: true, max: 50 }" :name="`${$tc('message.parent1', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.parent1', 1)} (*)`">
                                        <b-form-input id="parent1"
                                                      type="text"
                                                      v-model="input.genitore_1"
                                                      :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Fiscal code 1 -->
                                <ValidationProvider :rules="{ taxCode: true, required: false }" :name="`${$tc('message.firstTaxCode', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.firstTaxCode', 1)}`">
                                        <b-form-input id="first-parent-fiscal-code"
                                                      v-model="input.codice_fiscale_r1"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Phone 1 -->
                                <ValidationProvider :rules="{ min: 0, max: 20, required: true }" :name="`${$tc('message.phone', 1)} 1 (*)`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.phone', 1)} 1 (*)`">
                                        <b-form-input id="phone1"
                                                      type="number"
                                                      min="0"
                                                      max="20"
                                                      v-model="input.telefono_principale"
                                                      :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Email 1 -->
                                <ValidationProvider :rules="{ max: 50, email: true }" :name="`${$tc('message.email', 1)} 1`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.email', 1)} 1`">
                                        <b-form-input id="email"
                                                      v-model="input.email_ufficiale"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="6" xl="6">
                                <!-- Parent 2 -->
                                <ValidationProvider :rules="{ max: 50 }" :name="`${$tc('message.parent2', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.parent2', 1)}`">
                                        <b-form-input id="parent1"
                                                      type="text"
                                                      v-model="input.genitore_2"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Fiscal code 2 -->
                                <ValidationProvider :rules="{ taxCode: true, required: false }" :name="`${$tc('message.otherTaxCode', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.otherTaxCode', 1)}`">
                                        <b-form-input id="second-parent-fiscal-code"
                                                      v-model="input.codice_fiscale_r2"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Phone 2 -->
                                <ValidationProvider :rules="{ min: 0, max: 20, required: false }" :name="`${$tc('message.phone', 1)} 2`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.phone', 1)} 2`">
                                        <b-form-input id="phone2"
                                                      type="number"
                                                      min="0"
                                                      max="12"
                                                      v-model="input.telefono_secondario"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Email 2 -->
                                <ValidationProvider :rules="{ max: 50, email: true, required: false }" :name="`${$tc('message.email', 1)} 2`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.email', 1)} 2`">
                                        <b-form-input id="email2"
                                                      v-model="input.email_secondaria"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>

                        <b-form-row class="border m-2"/>

                        <b-row>
                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="6" xl="6">
                                <!-- Date start -->
                                <ValidationProvider :rules="{ required: true }" :name="`${$tc('message.dateStart', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.dateStart', 1)} (*)`">
                                        <b-input-group>
                                            <flat-pickr id="start-date"
                                                        ref="startDate"
                                                        :class="getFlatPickrClass('startDate', valid, errors, itemChanged, true)"
                                                        v-model="structureChild.data_iscrizione"
                                                        :config="startDateConfig">
                                            </flat-pickr>
                                            <div class="input-group-append">
                                                <b-button class="fas fa-undo"
                                                          @click="structureChild.data_iscrizione = getInitialRegistrationDate () "
                                                          :disabled="!structureChild.data_iscrizione">
                                                </b-button>
                                            </div>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-input-group>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="6" xl="6">
                                <!-- Discharge date -->
                                <ValidationProvider :name="`${$tc('message.discharge', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.discharge', 1)}`">
                                        <b-input-group>
                                            <flat-pickr id="end-date"
                                                        ref="endDate"
                                                        :class="getFlatPickrClass('endDate', valid, errors, itemChanged, false)"
                                                        v-model="structureChild.data_cancellazione"
                                                        :config="endDateConfig">
                                            </flat-pickr>

                                            <div class="input-group-append">
                                                <b-button class="fas fa-undo"
                                                          @click="structureChild.data_cancellazione = getInitialDischargeDate ()"
                                                          :disabled="!structureChild.data_cancellazione">
                                                </b-button>
                                            </div>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-input-group>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="6" xl="6">
                                <!-- Structure -->
                                <ValidationProvider :rules="{ required: true, checkCalendar: structureChild.id_calendario }" :name="`${$tc('message.structure', 1)} (*)`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.structure', 1)} (*)`">
                                        <v-select :options="structureOptions"
                                                  :reduce="obj => obj.id"
                                                  id="structure"
                                                  ref="structure"
                                                  label="den_sintetica"
                                                  :disabled="!!structureChild.id"
                                                  :class="checkSelectStyle('structure', itemChanged, valid, errors, true)"
                                                  v-model="structureChild.id_struttura">
                                        </v-select>

                                        <div id="inputLiveFeedback" v-if="errors.length !== 0" class="my-invalid-feedback">
                                            {{ lodash.first(errors) }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="6" xl="6">
                                <!-- Section -->
                                <b-form-group :label="`${$tc('message.section', 1)} `">
                                    <v-select :options="customSectionOptions"
                                              ref="sectionFilter"
                                              :reduce="obj => obj.id"
                                              v-model="structureChild.id_sezione"
                                              label="descrizione">
                                    </v-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row class="my-border-top mt-3">
                            <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                                <b-button id="change-structure"
                                          type="button"
                                          v-show="structureChild.id"
                                          :disabled="!structureChild.data_cancellazione || itemChanged"
                                          class="float-left mt-2"
                                          @click="showChangeStructureModal()"> {{$tc('message.changeStructure', 1)}}
                                </b-button>

                                <i class="fas fa-info-circle mt-3 ml-2 fa-lg c-green"
                                   v-if="structureChild.id && !structureChild.data_cancellazione || itemChanged"
                                   :title="$tc('message.info')"
                                   v-b-popover.hover.top="$tc('info.editStructureChildButton', 1)">
                                </i>
                            </b-col>

                            <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                                <b-button id="cancel-button"
                                          type="button"
                                          class="float-right ml-3 mt-2"
                                          @click="exitCheck(itemChanged)"> {{$tc('message.close', 1)}}
                                </b-button>

                                <b-button id="save-and-exit"
                                          type="button"
                                          class="float-right ml-3 mt-2 b-c-green"
                                          :disabled="invalid || pending || !itemChanged"
                                          @click="save(true)"> {{$tc('message.saveClose', 1)}}
                                </b-button>

                                <b-button id="save"
                                          type="button"
                                          class="float-right ml-3 mt-2 b-c-green"
                                          :disabled="invalid || pending || !itemChanged"
                                          @click="save(false)"> {{$tc('message.save', 1)}}
                                </b-button>
                            </b-col>
                        </b-row>
                    </ValidationObserver>
                </div>
            </div>
        </div>

        <div class="modal-dialog modal-lg" v-show="allStructureChild && allStructureChild.length > 1">
            <div class="modal-content">
                <header class="modal-header">
                    <h5 class="my-modal-title">{{$tc('info.childHistoryForCurrentYear', 1)}} {{currentYear}}</h5>
                </header>

                <div class="modal-body">
                    <b-row>
                        <standard-table ref="mainTable"
                                        :items="getItems()"
                                        :fields="getFields()"
                                        :theadTrClass="itemChanged ? '' : 'first-table-cols'"
                                        fullWidth="true"
                                        @rowEdit="showChangeStructureModal">
                        </standard-table>
                    </b-row>
                </div>
            </div>
        </div>

        <b-modal ref="changeStructure" id="changeStructure" :title="`${$t('message.changeStructure')}`" hide-footer>
            <change-structure-child ref="changeStructurePlugIn"
                                    :childId="childId"
                                    :structures="structureOptions"
                                    :calendars="calendarOptions"
                                    :sections="sectionOptions"
                                    :years="yearOptions"
                                    :yearId="yearId"
                                    :currentStructureChild="editStructureChild"
                                    :allStructureChild="allStructureChild"
                                    @save="saveStructureChild($event, childId)"
                                    @close="hideChangeStructureModal">
            </change-structure-child>
        </b-modal>
    </b-container>
</template>

<script>
  import Vue from 'vue'
  import _ from 'lodash'
  import { DateTime } from 'luxon'
  import { Italian } from 'flatpickr/dist/l10n/it.js'

  export default {
    name: 'EditChild',
    created () {
      this.fetchData().then(() => {
        this.addWatcher()
      })
    },
    mounted () {
      this.$root.$on('change-lang', () => {
        this.$nextTick(() => {
          if (this && this.$refs && this.$refs.form) this.$refs.form.validate()
        })
      })
    },
    data () {
      const yearId = Vue.getLocalStorage('year')
      const childId = this.$route.params.id
      const lodash = _
      const backPath = '/registry/child'
      const dateConfig = {
        dateFormat: 'Y-m-d',
        mode: 'single',
        altFormat: 'D j M Y',
        altInput: true,
        locale: Italian,
        minDate: '',
        maxDate: '',
        disable: []
      }
      const genericStructureChild = {
        id_struttura: null,
        id_anno_scolastico: yearId,
        id_calendario: null,
        data_cancellazione: null,
        data_iscrizione: null,
        id_bambino: null,
        id_sezione: null
      }
      return {
        DateTime,
        backPath,
        childId,
        lodash,
        yearId,
        currentYear: null,
        input: {},
        originalStructureChild: null,
        structureChild: _.cloneDeep(genericStructureChild),
        watcher: null,
        watcherStructureChild: null,
        watcherStructure: null,
        itemChanged: false,
        birthDateConfig: _.cloneDeep(dateConfig),
        startDateConfig: _.cloneDeep(dateConfig),
        endDateConfig: _.cloneDeep(dateConfig),
        yearOptions: [],
        structureOptions: [],
        sectionOptions: [],
        customSectionOptions: [],
        calendarOptions: [],
        allStructureChild: [],
        editStructureChild: {}
      }
    },
    methods: {
      resetWatcher () {
        this.$root.$emit('activeLoader', true)
        if (this.watcher) this.watcher()
        if (this.watcherStructureChild) this.watcherStructureChild()
        if (this.watcherStructure) this.watcherStructure()
        this.itemChanged = false
      },
      addWatcher () {
        this.$root.$emit('activeLoader', false)
        this.watcher = this.$watch('input', () => {
          this.itemChanged = true
        }, { deep: true })
        this.watcherStructureChild = this.$watch('structureChild', newVal => {
          this.checkDateConfig(newVal)
          this.itemChanged = true
        }, { deep: true })
        this.watcherStructure = this.$watch('structureChild.id_struttura', val => {
          this.customSectionOptions = this.getSectionOptions(this.sectionOptions, val, true)
        })
      },
      fetchData () {
        this.resetWatcher()
        return this.getOptions().then(() => {
          return this.getChild()
        })
      },
      getOptions () {
        const axiosArray = []
        axiosArray.push(this.getYears())
        axiosArray.push(this.getStructures())
        axiosArray.push(this.getSections())
        axiosArray.push(this.getCalendars())
        return Vue.myAll(axiosArray)
      },
      getYears () {
        return Vue.myGet(this, '/anno_scolastico', 'anni').then(years => {
          const currentYear = _.find(years, obj => obj.id === this.yearId)
          const min = `${currentYear.anno}-09-01`
          const max = `${currentYear.anno + 1}-08-31`

          this.yearOptions = years
          this.currentYear = currentYear.anno
          this.startDateConfig.minDate = min
          this.endDateConfig.minDate = min
          this.startDateConfig.maxDate = max
          this.endDateConfig.maxDate = max

          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      },
      getStructures () {
        return Vue.myGet(this, '/struttura', 'strutture').then(structures => {
          this.structureOptions = structures
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      },
      getSections () {
        return Vue.myGet(this, '/sezione', 'sezioni').then(sections => {
          this.sectionOptions = sections
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      },
      getCalendars () {
        return Vue.myGet(this, `/calendario?anno=${this.yearId}`, 'calendari').then(calendars => {
          this.calendarOptions = calendars
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      },
      getSectionOptions (originalItems, structure, reset) {
        if (this.$refs.sectionFilter && reset) this.$refs.sectionFilter.clearSelection()
        const foundedCalendar = _.find(this.calendarOptions, obj => obj.id_struttura === structure && obj.standard)
        this.structureChild.id_calendario = foundedCalendar ? foundedCalendar.id : null
        return _.filter(_.cloneDeep(originalItems), obj => obj.id_struttura === structure)
      },
      getChild () {
        if (this.childId !== 'new') {
          return Vue.myGet(this, `/bambino/${this.childId}`, 'bambini').then((child) => {
            this.input = child
            return this.getStructureChild(child.id)
          }).catch(error => {
            Vue.manageErrors(this, error)
            return true
          })
        } else {
          this.input = {}
          this.structureChild = {
            id_struttura: null,
            id_anno_scolastico: this.yearId,
            id_calendario: null,
            data_cancellazione: null,
            data_iscrizione: null
          }
          return new Promise(resolve => resolve())
        }
      },
      getStructureChild (childId) {
        return Vue.myGet(this, `/struttura_bambino?bambino=${childId}&anno=${this.yearId}`, 'associazioni').then((associations) => {
          this.allStructureChild = associations
          this.structureChild = {
            id_struttura: null,
            id_anno_scolastico: this.yearId,
            id_calendario: null,
            data_cancellazione: null,
            data_iscrizione: null,
            id_bambino: null,
            id_sezione: null
          }
          let activeAssociation = null

          if (associations && associations.length !== 0) {
            associations.forEach(association => {
              if (activeAssociation && activeAssociation.data_cancellazione) {
                activeAssociation = DateTime.fromISO(association.data_iscrizione) > DateTime.fromISO(activeAssociation.data_iscrizione) ? association : activeAssociation
              } else if (!activeAssociation || !activeAssociation.data_cancellazione) {
                activeAssociation = association
              }
            })
          }

          if (activeAssociation) this.structureChild = activeAssociation
          this.originalStructureChild = _.cloneDeep(this.structureChild)
          this.customSectionOptions = this.getSectionOptions(this.sectionOptions, this.structureChild && this.structureChild.id_struttura)
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      },
      exitCheck (itemChanged) {
        if (itemChanged) this.$root.$emit('exit-check', { redirect: this.backPath })
        else this.$router.push(this.backPath)
      },
      save (pushBack) {
        const editedItem = this.parseInputItem(_.cloneDeep(this.input))
        editedItem.codice_fiscale = editedItem.codice_fiscale.toUpperCase()
        Vue.patchOrPost(this.childId !== 'new',
        `/bambino${this.childId !== 'new' ? '/' + this.childId : ''}`, editedItem).then(r => {
          this.structureChild.id_bambino = this.input.id || r.data.id
          this.saveStructureChild(this.structureChild, this.input.id || r.data.id, pushBack)
        }).catch(errorObj => {
          const error = errorObj.response
          if (error && error.data && error.data.constraint && error.data.constraint === 'codice_fiscale' &&
          this.$refs && this.$refs.validationTaxCode) {
            this.$refs.validationTaxCode.setErrors([this.$tc('constraints.codice_fiscale', 1)])
          }
          Vue.manageErrors(this, errorObj)
        })
      },
      saveStructureChild (structureChild, childId, pushBack) {
        const editedItem = this.parseInputItem(_.cloneDeep(structureChild))
        Vue.patchOrPost(editedItem.id,
        `/struttura_bambino${editedItem.id ? '/' + editedItem.id : ''}`, editedItem).then(() => {
          Vue.success(this, `${this.$tc('message.child', 1)} ${this.$t(this.childId !== 'new' ? 'success.putSuccess' : 'success.postSuccess')}`)
          if (pushBack) this.$router.push(this.backPath)
          else this.$router.push({ name: 'RefreshPage', params: { id: childId, name: 'EditChild' } })
        }).catch(error => {
          Vue.manageErrors(this, error)
        })
      },
      parseInputItem (item) {
        const editedItem = {}
        const timestampFields = ['data_iscrizione', 'data_cancellazione']
        const intFields = ['id', 'id_anno_scolastico', 'id_struttura', 'id_bambino', 'id_calendario', 'id_sezione']
        const boolFields = ['removed']
        _.forOwn(item, (val, field) => {
          const mustBeTimestamp = _.find(timestampFields, obj => obj === field)
          const mustBeInt = _.find(intFields, obj => obj === field)
          const mustBeBool = _.find(boolFields, obj => obj === field)
          if (mustBeTimestamp) {
            editedItem[field] = val ? DateTime.fromSQL(val).toISO() : null
          } else if (mustBeInt) {
            editedItem[field] = val === 0 ? 0 : (parseInt(val) || null)
          } else if (mustBeBool) {
            editedItem[field] = !!val
          } else {
            editedItem[field] = val === 0 ? 0 : (val || null)
          }
        })
        return editedItem
      },
      checkDateConfig (obj) {
        const min = `${this.currentYear}-09-01`
        const max = `${this.currentYear + 1}-08-31`

        this.startDateConfig.minDate = min
        this.endDateConfig.minDate = obj.data_iscrizione ? obj.data_iscrizione : min
        this.startDateConfig.maxDate = obj.data_cancellazione ? obj.data_cancellazione : max
        this.endDateConfig.maxDate = max
      },
      getFlatPickrClass (ref, valid, errors, itemChanged, isRequired) {
        const nullClass = 'form-control'
        const falseClass = 'form-control is-invalid'
        const trueClass = 'form-control is-valid'
        const result = isRequired
        ? _.first(errors) && itemChanged ? falseClass : (valid ? (itemChanged ? trueClass : nullClass) : nullClass)
        : _.first(errors) ? falseClass : nullClass

        if (this.$refs && this.$refs[ref] && this.$refs[ref].$el && this.$refs[ref].$el.parentElement) {
          const el = this.$refs[ref].$el.parentElement.children && this.$refs[ref].$el.parentElement.children[1]
          el.className = result
        }

        return result
      },
      checkSelectStyle (ref, groupChanged, valid, errors, required) {
        Vue.MySelectValidationClass(this, ref, groupChanged, valid, errors, required)
      },
      showChangeStructureModal (event) {
        this.editStructureChild = (event && event.item) || null
        this.$refs.changeStructure.show()
      },
      hideChangeStructureModal () {
        this.$refs.changeStructure.hide()
      },
      getInitialBirthDate () {
        return this.originalStructureChild.bambino.data_nascita
      },
      getInitialRegistrationDate () {
        return this.originalStructureChild.data_iscrizione
      },
      getInitialDischargeDate () {
        return this.originalStructureChild.data_cancellazione
      },
      // structure child history
      getItems () {
        const editedItems = []
        if (this.allStructureChild && this.allStructureChild.length !== 0) {
          this.allStructureChild.forEach(item => {
            if (item.id !== this.structureChild.id) {
              const foundedStructure = _.find(this.structureOptions, obj => obj.id === item.id_struttura)
              const foundedCalendar = _.find(this.calendarOptions, obj => obj.id === item.id_calendario)
              const foundedSection = _.find(this.sectionOptions, obj => obj.id === item.id_sezione)
              const editedItem = item

              editedItem.structure = (foundedStructure && foundedStructure.den_sintetica) || this.$tc('message.emptyValue', 1)
              editedItem.calendar = (foundedCalendar && foundedCalendar.descrizione) || this.$tc('message.emptyValue', 1)
              editedItem.section = (foundedSection && foundedSection.descrizione) || this.$tc('message.emptyValue', 1)
              editedItem.startDate = item.data_iscrizione ? DateTime.fromISO(item.data_iscrizione).toFormat('dd-MM-y') : this.$tc('message.emptyValue', 1)
              editedItem.endDate = item.data_cancellazione ? DateTime.fromISO(item.data_cancellazione).toFormat('dd-MM-y') : this.$tc('message.emptyValue', 1)

              editedItems.push(item)
            }
          })
        }
        return editedItems
      },
      getFields () {
        return [
          { key: 'edit', label: '', hideInTable: this.itemChanged },
          { key: 'structure', label: `${this.$tc('message.structure', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'section', label: `${this.$tc('message.section', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'startDate', label: `${this.$tc('message.dateStart', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'endDate', label: `${this.$tc('message.discharge', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'endDate', hideInTable: true, hideInExcel: true }
        ]
      }
    }
  }
</script>
